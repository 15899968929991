<template>
  <EditLocationDialog
    value="true"
    :location="location"
    :locations="orderedLocations"
    :error-message="errorMessage"
    :update-loading="updateLoading"
    :data-loading="dataLoading"
    :first-location="firstLocation"
    @ok="onOk"
    @cancel="onCancel"
  />
</template>

<script>
import { sortBy } from "lodash";
import EditLocationDialog from "./components/EditLocationDialog";
import {
  LOCATION_CREATE,
  LOCATIONS_QUERY,
  LOCATION_QUERY,
  LOCATION_UPDATE,
  LOCATION_REPOSITION,
} from "./graphql";

export default {
  name: "EditLocationDialogView",
  components: { EditLocationDialog },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    locationId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      location: {},
      locations: [],
      errorMessage: undefined,
      updateLoading: false,
      locationLoading: 0,
      locationsLoading: 0,
    };
  },
  apollo: {
    locations: {
      loadingKey: "locationsLoading",
      query: LOCATIONS_QUERY,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      update(data) {
        const locations = data.locations;
        for (let locIndex = 0; locIndex < locations.length; locIndex++) {
          locations[locIndex].next = find(
            locations,
            (loc) => loc.id === locations[locIndex].nextLocationId
          );
        }

        return locations;
      },
    },
    location: {
      loadingKey: "locationLoading",
      query: LOCATION_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          id: this.locationId,
        };
      },
      skip() {
        return !this.locationId;
      },
      update(data) {
        return data.location;
      },
    },
  },
  computed: {
    dataLoading() {
      return !!this.locationLoading || !!this.locationsLoading;
    },
    firstLocation() {
      if (!this.orderedLocations || this.orderedLocations.length <= 0) {
        return undefined;
      }

      return this.orderedLocations[0];
    },
    orderedLocations() {
      return sortBy(this.locations, (loc) => loc.sortOrder);
    },
  },
  methods: {
    onCancel() {
      this.$router.back();
    },
    async onOk(createOrUpdateLocation) {
      this.errorMessage = undefined;
      this.updateLoading = true;
      let result = undefined;

      if (!this.locationId) {
        try {
          result = await this.handleCreateLocation(createOrUpdateLocation);
        } catch (error) {
          this.errorMessage = error.toString();
          this.updateLoading = false;
          return;
        }

        // TODO - what with this ?
        // why for creation we need to add something and for update we do not ?
        this.mutationUpdate(this.$apollo.getClient().cache, result);

        this.updateLoading = false;
        this.$router.back();
      } else {
        try {
          result = await this.handleUpdateLocation(
            this.locationId,
            createOrUpdateLocation
          );
        } catch (error) {
          this.errorMessage = error.toString();
          this.updateLoading = false;
          return;
        }

        this.updateLoading = false;
        this.$router.back();
      }
    },
    mutationUpdate(cache, result) {
      const newLocation = result?.data?.createLocation?.location;

      const variables = { storeId: this.storeId };
      const { locations } =
        cache.readQuery({
          query: LOCATIONS_QUERY,
          variables,
        }) || [];

      const index = locations.findIndex((x) => x.id === newLocation.id);

      if (index !== -1) return;

      locations.push(newLocation);
      cache.writeQuery({
        query: LOCATIONS_QUERY,
        variables,
        data: { locations },
      });
    },
    async handleCreateLocation(location) {
      const result = await this.$apollo.mutate({
        mutation: LOCATION_CREATE,
        variables: {
          input: {
            name: location.name.toString(), // To ensure if someone writes just number to have a string
            nextLocationId: location.nextLocationId,
          },
        },
      });

      const errors = result?.data?.createLocation?.errors;

      if (errors.length) {
        throw Error(errors.join("; "));
      }

      return result;
    },
    async handleUpdateLocation(locationId, location) {
      const result = await this.$apollo.mutate({
        mutation: LOCATION_UPDATE,
        variables: {
          input: {
            id: locationId,
            name: location.name.toString(),
            nextLocationId: location.nextLocationId,
          },
        },
      });

      if (location.default) {
        await this.$apollo.mutate({
          mutation: REPOSITION_LOCATION,
          variables: {
            input: {
              id: locationId,
              position: 1,
            },
          },
        });

        const { data } = await this.$apollo.query({
          query: LOCATIONS_QUERY,
          fetchPolicy: "network-only",
          variables: {
            storeId: this.storeId,
          },
        });

        const variables = { storeId: this.storeId };
        this.$apollo.getClient().cache.writeQuery({
          query: LOCATIONS_QUERY,
          variables,
          data: { locations: data.locations },
        });
      }

      const errors = result?.data?.updateLocation?.errors;

      if (errors.length) {
        throw Error(errors.join("; "));
      }

      return result;
    },
  },
};
</script>
