var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('VxDialog',_vm._b({attrs:{"v-size":'medium',"h-size":'xsmall',"persistent":"","title":_vm.dialogTitle},on:{"back":_vm.cancel},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("backstock.setup.locations.editLocationDialog.cancelButtonText"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid,"loading":_vm.updateLoading},on:{"click":function($event){return handleSubmit(_vm.ok)}}},[_vm._v(" "+_vm._s(_vm.$t("backstock.setup.locations.editLocationDialog.okButtonText"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},'VxDialog',_vm.$props,false),[(!_vm.dataLoading)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.ok)}}},[_vm._t("description"),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('VxTextField',{attrs:{"name":"locationName","label":_vm.$t(
                'backstock.setup.locations.editLocationDialog.locationNameLabel'
              ),"placeholder":_vm.$t(
                'backstock.setup.locations.editLocationDialog.locationNamePlaceholder'
              ),"rules":"required"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", _vm._n($$v))},expression:"form.name"}})],1)],1),(_vm.errorMessage)?_c('VxAlert',{attrs:{"type":"error"}},[_c('strong',[_vm._v("Note:")]),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.form.terminal)?_c('VxAlert',{attrs:{"type":"warning"}},[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              `backstock.setup.locations.editLocationDialog.terminalAlert[0]`
            )
          )}}),_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              `backstock.setup.locations.editLocationDialog.terminalAlert[1]`
            )
          )}})]):_vm._e(),(!_vm.form.terminal)?_c('RaiSection',{attrs:{"title":_vm.$t('backstock.setup.locations.editLocationDialog.optionalText'),"borderless":""}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "backstock.setup.locations.editLocationDialog.nextLocationDescription" ))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('VxSelect',{attrs:{"label":_vm.$t(
                  'backstock.setup.locations.editLocationDialog.nextLocationLabel'
                ),"name":"nextLocationId","items":_vm.locations,"item-text":"name","item-value":"id"},model:{value:(_vm.form.nextLocationId),callback:function ($$v) {_vm.$set(_vm.form, "nextLocationId", $$v)},expression:"form.nextLocationId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isDefaultLocation)?_c('strong',[_vm._v(_vm._s(_vm.$t( "backstock.setup.locations.editLocationDialog.defaultLocationText" )))]):_vm._e(),(!_vm.isDefaultLocation)?_c('v-checkbox',{attrs:{"label":_vm.$t(
                  'backstock.setup.locations.editLocationDialog.defaultLocationLabel'
                ),"name":"default"},on:{"change":_vm.setDefaultLocation}}):_vm._e()],1)],1)],1):_vm._e()],2):_vm._e()],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }