<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }" slim>
    <VxDialog
      v-model="modelValue"
      :v-size="'medium'"
      :h-size="'xsmall'"
      v-bind="$props"
      persistent
      :title="dialogTitle"
      @back="cancel"
    >
      <v-form v-if="!dataLoading" ref="form" @submit.prevent="handleSubmit(ok)">
        <slot name="description"></slot>
        <v-row class="mb-2">
          <v-col cols="12">
            <VxTextField
              v-model.number="form.name"
              name="locationName"
              :label="
                $t(
                  'backstock.setup.locations.editLocationDialog.locationNameLabel'
                )
              "
              :placeholder="
                $t(
                  'backstock.setup.locations.editLocationDialog.locationNamePlaceholder'
                )
              "
              rules="required"
            />
          </v-col>
        </v-row>
        <VxAlert v-if="errorMessage" type="error">
          <strong>Note:</strong> {{ errorMessage }}
        </VxAlert>
        <VxAlert v-if="form.terminal" type="warning">
          <p
            v-html="
              $t(
                `backstock.setup.locations.editLocationDialog.terminalAlert[0]`
              )
            "
          />
          <p
            v-html="
              $t(
                `backstock.setup.locations.editLocationDialog.terminalAlert[1]`
              )
            "
          />
        </VxAlert>
        <RaiSection
          v-if="!form.terminal"
          :title="
            $t('backstock.setup.locations.editLocationDialog.optionalText')
          "
          borderless
        >
          <p>
            {{
              $t(
                "backstock.setup.locations.editLocationDialog.nextLocationDescription"
              )
            }}
          </p>
          <v-row>
            <v-col cols="12">
              <VxSelect
                v-model="form.nextLocationId"
                :label="
                  $t(
                    'backstock.setup.locations.editLocationDialog.nextLocationLabel'
                  )
                "
                name="nextLocationId"
                :items="locations"
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <strong v-if="isDefaultLocation">{{
                $t(
                  "backstock.setup.locations.editLocationDialog.defaultLocationText"
                )
              }}</strong>
              <v-checkbox
                v-if="!isDefaultLocation"
                :label="
                  $t(
                    'backstock.setup.locations.editLocationDialog.defaultLocationLabel'
                  )
                "
                name="default"
                @change="setDefaultLocation"
              />
            </v-col>
          </v-row>
        </RaiSection>
      </v-form>

      <template #actions>
        <v-btn color="grey" text @click="cancel">
          {{
            $t("backstock.setup.locations.editLocationDialog.cancelButtonText")
          }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="invalid"
          :loading="updateLoading"
          @click="handleSubmit(ok)"
        >
          {{ $t("backstock.setup.locations.editLocationDialog.okButtonText") }}
        </v-btn>
      </template>
    </VxDialog>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import {
  RaiSection,
  VxAlert,
  VxSelect,
  VxDialog,
  VxTextField,
  useModelValue,
  useResponsiveness,
} from "@/core-ui";

const defaultLocation = () => ({
  id: 0,
  name: "",
  nextLocationId: 0,
  default: false,
});

export default {
  name: "EditLocationDialog",
  components: {
    VxTextField,
    VxAlert,
    VxSelect,
    RaiSection,
    VxDialog,
    ValidationObserver,
  },
  mixins: [useModelValue(), useResponsiveness()],
  props: {
    location: {
      type: Object,
      default: () => defaultLocation(),
    },
    locations: {
      type: Array,
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    updateLoading: {
      type: Boolean,
      default: false,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    firstLocation: {
      type: Object,
      default: () => ({ id: 0 }),
    },
  },
  data: (vm) => ({
    form: { ...vm.location },
  }),
  computed: {
    isEditMode() {
      return this.location.id > 0;
    },
    dialogTitle() {
      return this.dataLoading
        ? this.$t("backstock.setup.locations.editLocationDialog.loadingText")
        : this.isEditMode
        ? this.$t("backstock.setup.locations.editLocationDialog.titleEdit")
        : this.$t("backstock.setup.locations.editLocationDialog.titleAdd");
    },
    isDefaultLocation() {
      return this.location.id === this.firstLocation.id;
    },
  },
  watch: {
    // Needed because of handling a case of loading a location, which is initially undefined
    location(newLocation) {
      this.form = { ...newLocation };
    },
  },
  methods: {
    setDefaultLocation(value) {
      this.form.default = value;
    },
    getValidator() {
      return this.$refs.observer;
    },
    reset() {
      this.form = defaultLocation();
      requestAnimationFrame(() => this.getValidator().reset());
      this.$emit("reset");
    },
    cancel() {
      this.modelValue = false;
      this.$emit("cancel");
    },
    ok() {
      this.modelValue = false;
      this.$emit("ok", { ...this.form });
    },
  },
};
</script>
